/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif;  }

.spinner{
    display: flex;
    justify-content: center;
    align-items: center;
}

.mat-tab-body-content {
    overflow: hidden !important;
}
.box {

    background-color: hsla(253, 81%, 23%, 0.884) !important;
 
}